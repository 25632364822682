import {format} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import '../styles/artwork-single.css'
import {Link} from 'gatsby'
import ReactPlayer from 'react-player'


function Artwork (props) {
  const {title, slug, festivalYear, category, authors, _rawDescription, images, youtube, webLink, pdf} = props

  return (
    <Container>

    <div id="festival-artwork">
      <div className="wrapper">
        <div id="festival-artwork-inner">

          <h2>Artwork</h2>

          <div className="artwork-item grid">

            <div className="width-3-12-m width-3-12">
              <p>{festivalYear.title}</p>
              <p>{category.title}</p>

              {authors &&
                  authors.map(author => (
                  <p>
                      {author.name}
                  </p>    
                  ))
                } 

            </div>

            <div className="width-5-12-m width-6-12">
                <p className="artwork-title">{title}</p>
                <PortableText blocks={_rawDescription} />

                {pdf && 
                  <div className="pdf">
                  {pdf && pdf.file && 
                      <p className="extras"><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
                  }
                  </div>
                } 

                {webLink && <p><a target="_blank" rel="noopener noreferrer" href={webLink}>View Online</a></p> }

            </div>

          </div>

          <div className="artwork-content grid">

            <div className="width-12-12-m width-9-12">

              {youtube && youtube.url && (
                <div className="video-holder">
                  <p className="artwork-title">Video</p>
                  <div className='player-wrapper'>
                    <ReactPlayer 
                      className='react-player'
                      url={youtube.url}
                      width='100%'
                      height='100%'
                    />
                  </div>
                </div>
              )}
            
              {images && images[0] &&

                <div className="artwork-gallery">

                <p className="artwork-title">Images</p>

                <div className="grid">

                {images && images.map(image => {

                  return image && image.asset && (

                  <div className="gallery-image width-12-12 width-12-12-m">
                  <a data-attribute="SRL" href={imageUrlFor(buildImageObj(image))
                        .width(2000)
                        .url()}
                  >
                    <img
                      src={imageUrlFor(buildImageObj(image))
                        .width(2000)
                        .url()}
                      alt={image.alt}
                    />
                    </a>
                  </div>
                )
                })}

                </div>

                </div>

            }

            </div>

          </div>

        </div>

      </div>
    </div>

    </Container>
  )
}

export default Artwork